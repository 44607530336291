<style lang="scss">
  @import "@/assets/scss/allo-logo";
</style>

<script setup>

</script>

<template>
  <svg
    class="allo-logo"
    width="60"
    height="35"
    viewBox="0 0 600 350" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M310.722 275.237C316.367 275.237 320.943 270.661 320.943 265.016V10.2216C320.943 4.29816 315.924 -0.384914 310.015 0.0250014L273.219 2.57749C267.861 2.94913 263.705 7.40364 263.705 12.7741V265.016C263.705 270.661 268.281 275.237 273.926 275.237H310.722Z" fill="currentColor"></path>
    <path d="M235.086 265.016C235.086 270.661 230.51 275.237 224.865 275.237H188.069C182.424 275.237 177.847 270.661 177.847 265.016V49.5868C177.847 44.2177 182.002 39.7638 187.358 39.3904L224.154 36.8251C230.064 36.413 235.086 41.0966 235.086 47.0214V265.016Z" fill="currentColor"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.7962 149.741C27.0547 151.697 20.5159 148.999 18.4922 143.281L10.0623 119.462C8.40097 114.768 10.3514 109.516 14.935 107.57C18.6686 105.985 22.793 104.516 27.3081 103.163C35.4526 100.794 43.9565 98.8988 52.8196 97.4775C61.9223 96.0561 70.0669 95.3454 77.2532 95.3454C92.105 95.3454 105.04 98.1882 116.059 103.874C127.318 109.559 135.942 117.495 141.93 127.682C148.158 137.868 151.273 149.713 151.273 163.216V262.796C151.273 268.441 146.696 273.017 141.051 273.017H125.19C121.935 273.017 118.874 271.467 116.949 268.842L114.271 265.192C110.398 259.912 102.649 259.644 97.6281 263.848C94.2306 266.693 90.6728 269.157 86.9548 271.24C79.7684 275.268 72.103 277.281 63.9584 277.281C42.3994 277.281 26.3499 271.951 15.81 261.291C5.26998 250.631 0 236.654 0 219.36C0 201.119 6.22816 187.853 18.6845 179.562C31.1408 171.034 47.3101 166.769 67.1923 166.769H93.4608C93.9715 166.769 94.3315 166.268 94.1684 165.784C94.1502 165.731 94.1383 165.674 94.1326 165.618C93.402 158.316 90.7698 152.778 86.2361 149.002C81.9243 144.975 76.295 142.961 69.3482 142.961C62.1619 142.961 54.1371 144.027 45.274 146.16C40.811 147.233 36.6517 148.427 32.7962 149.741ZM52.8196 205.857C49.2265 209.648 47.4299 214.03 47.4299 219.005C47.4299 221.848 48.1485 224.454 49.5858 226.822C51.2626 229.191 53.2987 231.087 55.6942 232.508C58.3291 233.929 61.2037 234.64 64.3178 234.64C69.3482 234.64 74.4984 233.574 79.7684 231.442C83.7614 229.647 87.6169 227.308 91.3349 224.425C93.6403 222.638 94.8597 219.824 94.8597 216.906V210.393C94.8597 204.748 90.2836 200.172 84.6386 200.172H67.5516C61.3235 200.172 56.4128 202.067 52.8196 205.857Z" fill="currentColor"></path>
    <path d="M599.878 106.512C602.386 139.35 565.395 164.781 560.139 157.911C557.703 154.727 559.885 152.593 562.619 149.919C565.783 146.824 569.688 143.006 568.026 135.998C566.541 129.735 560.167 127.931 552.968 125.893C545.151 123.679 536.36 121.191 531.799 112.419C523.037 95.5704 531.991 75.19 552.688 70.3714C573.385 65.5527 597.37 73.6732 599.878 106.512Z" fill="currentColor"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M384.268 266.814C398.348 273.673 414.657 277.103 433.195 277.103C451.498 277.103 467.69 273.555 481.769 266.459C496.083 259.363 507.347 249.074 515.56 235.592C523.773 222.109 527.88 206.025 527.88 187.339C527.88 167.707 523.891 151.15 515.912 137.667C507.934 123.948 496.905 113.541 482.825 106.445C468.746 99.349 452.437 95.801 433.899 95.801C415.83 95.801 399.639 99.4673 385.324 106.8C371.245 114.132 360.099 124.54 351.886 138.022C343.907 151.504 339.801 167.825 339.566 186.984C339.566 206.143 343.438 222.582 351.182 236.301C359.16 249.784 370.189 259.955 384.268 266.814ZM453.258 226.012C447.861 229.56 441.291 231.334 433.547 231.334C426.038 231.334 419.467 229.442 413.836 225.657C408.438 221.873 404.214 216.669 401.164 210.046C398.113 203.187 396.588 195.499 396.588 186.984C396.588 177.76 398.113 169.836 401.164 163.213C404.214 156.59 408.556 151.386 414.188 147.602C420.054 143.817 426.742 141.925 434.251 141.925C441.76 141.925 448.213 143.935 453.61 147.956C459.242 151.741 463.466 157.063 466.282 163.922C469.332 170.782 470.858 178.587 470.858 187.339C470.858 196.091 469.332 203.778 466.282 210.401C463.231 217.024 458.89 222.228 453.258 226.012Z" fill="currentColor"></path>
  </svg>
</template>
