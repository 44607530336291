<style lang="scss">
  @import "@/assets/scss/booger-logo";
</style>

<script setup>

</script>

<template>
  <svg
    viewBox="0 0 26 22.3"
    class="booger-logo"
  >
<g id="surface1">
  <path d="M12.9,20.5c-2.2,0-4-1-4-5.1s1.8-6.8,4-6.8s4,2.7,4,6.8S15.2,20.5,12.9,20.5z"/>
  <path class="booger-logo__brown" d="M12.9,16.5c-1.6,0-2.8-0.3-2.8-2.6s1.8-3.7,2.8-3.7s2.8,1.4,2.8,3.7S14.5,16.5,12.9,16.5z"/>
  <path d="M18.6,21.2c0-0.5-0.7-2.4-2.8-2.4c-1.7,0-2.3,1.7-2.3,2.6c0,0.7,1.3,0.9,2.8,0.9C18.1,22.2,18.6,21.7,18.6,21.2z"/>
  <path d="M7.3,21.2c0-0.5,0.7-2.4,2.8-2.4c1.7,0,2.3,1.7,2.3,2.6c0,0.7-1.3,0.9-2.8,0.9C7.8,22.2,7.3,21.7,7.3,21.2z"/>
  <path d="M9,5.7c0,2.5-2,4.5-4.5,4.5S0,8.2,0,5.7s2-4.5,4.5-4.5S9,3.2,9,5.7z"/>
  <path d="M26,5.7c0,2.5-2,4.5-4.5,4.5S17,8.2,17,5.7s2-4.5,4.5-4.5S26,3.2,26,5.7z"/>
  <path class="booger-logo__brown" d="M24.9,5.7c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4C23.3,2.3,24.9,3.8,24.9,5.7z"/>
  <path class="booger-logo__brown" d="M7.8,5.7c0,1.9-1.5,3.4-3.4,3.4S1,7.6,1,5.7s1.5-3.4,3.4-3.4S7.8,3.8,7.8,5.7z"/>
  <path d="M19.8,5.7c0,3.1-1.9,5.7-6.8,5.7S6.1,8.8,6.1,5.7S8,0,12.9,0S19.8,2.6,19.8,5.7z"/>
  <path class="booger-logo__brown" d="M7.3,5.7c0,2.5,1.1,4.5,5.7,4.5s5.7-2,5.7-4.5S17.6,1.2,13,1.2S7.3,3.2,7.3,5.7z"/>
  <path class="booger-logo__white" d="M16.9,6c0,0.8-0.6,1.4-1.4,1.4S14.1,6.8,14.1,6s0.6-1.4,1.4-1.4S16.9,5.2,16.9,6z"/>
  <path class="booger-logo__white" d="M11.8,6c0,0.8-0.6,1.4-1.4,1.4C9.6,7.4,9,6.8,9,6s0.6-1.4,1.4-1.4C11.2,4.6,11.8,5.2,11.8,6z"/>
  <path class="booger-logo__red" d="M16.6,6.3c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1S16.6,5.7,16.6,6.3z"/>
  <path class=booger-logo__white d="M16.4,5.4c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3 C16.2,5.1,16.4,5.3,16.4,5.4z"/>
  <path class="booger-logo__red" d="M11.5,6.3c0,0.6-0.5,1.1-1.1,1.1S9.3,6.9,9.3,6.3s0.5-1.1,1.1-1.1S11.5,5.7,11.5,6.3z"/>
  <path class="booger-logo__red" d="M14.1,8.6c0,0-0.6,0.9-1.1,0.9c-0.6,0-1.1-0.9-1.1-0.9s0.6,0.3,1.1,0.3C13.5,8.9,14.1,8.6,14.1,8.6z"/>
  <path d="M16,3.4c-0.1,0-0.2,0-0.2-0.1c0,0-0.3-0.2-0.9-0.1c-0.2,0.1-0.5-0.1-0.5-0.3s0.1-0.5,0.3-0.5c0.9-0.2,1.4,0.2,1.5,0.2 c0.2,0.1,0.2,0.4,0.1,0.6C16.2,3.4,16.1,3.4,16,3.4z"/>
  <path d="M9.9,3.4c-0.1,0-0.2-0.1-0.3-0.2C9.5,3,9.5,2.8,9.7,2.6c0.1-0.1,0.6-0.4,1.5-0.2c0.2,0.1,0.3,0.3,0.3,0.5 c-0.1,0.2-0.3,0.3-0.5,0.3c-0.5-0.1-0.9,0.1-0.9,0.1C10.1,3.4,10,3.4,9.9,3.4z"/>
  <path class=booger-logo__white d="M11.2,5.4c0,0.2-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3C11.1,5.1,11.2,5.3,11.2,5.4z"/>
  <path d="M19.8,15.4c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1c0-2.3-2.3-4-1.7-4C16.8,11.4,19.8,13,19.8,15.4z"/>
  <path d="M6.1,15.4c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1c0-2.3,2.3-4,1.7-4C9.1,11.4,6.1,13,6.1,15.4z"/>
  <g class="teeth">
		<polygon class="booger-logo__white" points="13,9.2 13.3,9.3 13.3,8.9 13,8.9 		"/>
    <polygon class="booger-logo__white" points="12.9,9.2 12.6,9.3 12.6,8.9 12.9,8.9 		"/>
	</g>
</g>
</svg>
</template>
