<style lang="scss">
  @import "@/assets/scss/booger-algorand";
</style>

<script setup>

</script>

<template>
  <svg
    class="booger-algorand"
    viewBox="0 0 180 49"
  >
  <g transform="translate(4.100017,2.010408)">
    <path d="M43.3,7.7v29.4h-6V7.7H43.3z"/>
    <path d="M67.5,17.2v19.9c0,6.2-4.8,9.9-10.6,9.9c-5.6,0-9.8-3.5-10.2-8.5l5.9,0c0.2,2.1,2.1,3,4.5,3c2.3,0,4.4-1.2,4.4-4.5v-2.5 c-1.5,1-3.5,1.6-5.6,1.6c-5.4,0-9.8-4.4-9.8-9.5c0-5.9,4.4-9.9,9.8-9.9c2.2,0,4.1,0.6,5.6,1.6v-1.1L67.5,17.2z M61.6,28.9v-4.8 c-1.2-1.8-2.9-2.4-4.6-2.4c-2.9,0-4.9,2-4.9,5c0,2.2,2,4.6,4.9,4.6C58.7,31.3,60.4,30.7,61.6,28.9z"/>
    <path d="M91.3,26.9c0,6.1-4.5,10.5-10.5,10.5c-6.1,0-10.6-4.4-10.6-10.5c0-6.1,4.5-10.5,10.6-10.5C86.8,16.4,91.3,20.9,91.3,26.9z M85.5,26.9c0-2.9-1.9-5.2-4.8-5.2c-2.9,0-4.8,2.3-4.8,5.2c0,3,1.9,5.2,4.8,5.2C83.7,32.1,85.5,29.9,85.5,26.9z"/>
    <path d="M128.2,17.3V37l-5.8,0V36c-1.5,1-3.5,1.6-5.6,1.6c-5.4,0-9.8-4.6-9.8-10.3c0-5.7,4.4-10.4,9.8-10.4c2.2,0,4.1,0.6,5.6,1.6 v-1.1L128.2,17.3z M122.4,30.2v-6c-1.2-1.8-2.9-2.3-4.6-2.3c-3,0-5.1,2.4-5.1,5.3c0,2.8,2.1,5.3,5.1,5.3 C119.4,32.5,121.1,32,122.4,30.2z"/>
    <path d="M152.2,26.3v10.7h-5.7V27c0-3.2-1.6-4.9-4.4-4.9c-1.5,0-3.1,0.9-4.5,2.5v12.4h-5.9V17.3h5.9v1.5c1.6-1.3,3.5-2,5.7-2 C148.4,16.8,152.2,20.5,152.2,26.3z"/>
    <path d="M34.6,37h-6l-4-14.7L16.1,37H9.4l13.1-22.8l-2.1-8L2.7,37h-6.8L18.3-2h6l2.6,9.7h6.1L28.8,15L34.6,37z"/>
    <path d="M175.9,7.7v29.4h-6V36c-1.5,1-3.5,1.6-5.6,1.6c-5.4,0-9.8-4.6-9.8-10.4c0-5.7,4.4-10.3,9.8-10.3c2.2,0,4.1,0.6,5.6,1.6V7.7 H175.9z M169.9,30.2v-6.1c-1.2-1.8-3-2.3-4.7-2.3c-3,0-5.1,2.4-5.1,5.3c0,2.9,2.1,5.3,5.1,5.3C167,32.5,168.7,32,169.9,30.2z"/>
    <path d="M105.9,22.3c-1.3,0.2-4.3,0.3-6.1,2.8v12h-5.9V17.4h5.9v1.5c1.3-1.1,3.3-1.8,6.1-1.6"/>
  </g>
</svg>
</template>
