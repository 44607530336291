<style lang="scss">
  @import "@/assets/scss/booger-pact";
</style>

<script setup>

</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 60 16.7"
    class="booger-pact"
  >
    <g>
      <path d="M3.3,0h7.9c1.9,0,3.4,0.4,4.4,1.2c1,0.8,1.5,2,1.5,3.4c0,2-0.7,3.5-2.1,4.5c-1.4,1.1-3.5,1.6-6.1,1.6H4.7l-1.1,5.5H0
			L3.3,0z M9.3,7.9c1.3,0,2.4-0.3,3.1-0.8c0.7-0.5,1.1-1.2,1.1-2.2c0-0.7-0.3-1.3-0.8-1.6c-0.5-0.3-1.3-0.5-2.4-0.5h-4L5.3,7.9H9.3z
			"/>
      <path d="M20.3,16.6c-1.3,0-2.3-0.3-3-0.9c-0.7-0.6-1.1-1.4-1.1-2.4c0-2.8,2.4-4.2,7.2-4.3l3.4-0.1c0.1-0.5,0.1-0.8,0.1-1
			c0-1-0.9-1.5-2.7-1.5c-1.7,0-2.7,0.5-3.1,1.5L18,7.6C18.7,5.2,20.9,4,24.5,4c1.8,0,3.2,0.3,4.2,0.9c1,0.6,1.5,1.5,1.5,2.7
			c0,0.4-0.1,0.9-0.2,1.3l-1.5,7.3h-3.2l0.3-1.6c-0.9,0.7-1.8,1.2-2.6,1.5C22.3,16.5,21.3,16.6,20.3,16.6z M26.4,11L23.5,11
			c-1.4,0-2.4,0.2-3,0.5c-0.6,0.3-1,0.8-1,1.4c0,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.8,0.3,1.5,0.3c0.6,0,1.2-0.1,1.7-0.2
			c0.6-0.2,1.1-0.4,1.5-0.6c0.4-0.2,0.7-0.5,1-0.9c0.3-0.3,0.4-0.7,0.5-1.1L26.4,11z"/>
      <path d="M37.7,16.7c-1.2,0-2.3-0.2-3.3-0.6c-0.9-0.4-1.7-1-2.2-1.8c-0.5-0.8-0.8-1.7-0.8-2.8c0-1.4,0.3-2.7,0.9-3.8
			c0.6-1.1,1.5-2,2.7-2.6C36.3,4.3,37.7,4,39.3,4c1,0,2,0.2,2.8,0.5c0.8,0.3,1.5,0.8,2.1,1.5c0.5,0.6,0.9,1.4,1,2.3l-3.4,0.4
			c-0.3-1.4-1.2-2.1-2.6-2.1c-1.2,0-2.2,0.4-2.9,1.2C35.4,8.7,35,9.8,35,11.2c0,0.9,0.3,1.6,0.8,2.1c0.5,0.5,1.3,0.8,2.2,0.8
			c1.7,0,2.8-0.7,3.2-2.1h3.5c-0.3,1-0.7,1.9-1.3,2.5c-0.6,0.7-1.4,1.2-2.4,1.6C40.1,16.5,39,16.7,37.7,16.7z"/>
      <path d="M51.2,16.5c-2.6,0-3.9-1-3.9-3c0-0.4,0.1-0.8,0.2-1.3l1.1-5.3h-2.3l0.5-2.5h2.3l0.4-1.8l3.7-1.3l-0.6,3.2h3.9l-0.5,2.5H52
			L50.9,12c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.8,0.5,1.1,1.6,1.1c0.5,0,1.1-0.1,1.7-0.3l0.4,2.4C53.3,16.4,52.3,16.5,51.2,16.5z"/>
    </g>
    <g>
      <g>
        <path d="M59,6.4l-0.3-0.5h-0.4v0.5h-0.3V4.9h0.7c0.4,0,0.6,0.2,0.6,0.5c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.2,0.2l0.4,0.6
				L59,6.4L59,6.4z M58.6,5.6c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.2c0,0-0.1,0-0.2,0h-0.4v0.4L58.6,5.6
				L58.6,5.6z"/>
      </g>
      <g>
        <path d="M58.6,7.1c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4S59.4,7.1,58.6,7.1z M58.6,4.5
				c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2S59.2,4.5,58.6,4.5z"/>
      </g>
    </g>
  </svg>

</template>
