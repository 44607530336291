<style lang="scss">
  @import "@/assets/scss/booger-tinyman";
</style>

<script setup>

</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="booger-tinyman"
    viewBox="0 0 200.1 76.1"
  >
    <path style="fill-rule:evenodd;clip-rule:evenodd;" d="M17.5,0.6c3.6-1.6,7.8,0.3,9,4L30,14.7c2.2,1.9,3.5,4.7,3.5,7.6v25.2
	c0,2.3-1.8,4.1-4.1,4.1c-0.1,0-0.1,0-0.2,0v10.9c0,1.7,1.2,3.1,2.8,3.4l1.5,0.3c1.7,0.3,3,1.8,3,3.6c0,0.4-0.1,0.8-0.2,1.2
	c0,0.1,0,0.1,0,0.2c0,2.7-8.1,4.9-18.1,4.9S0,73.9,0,71.2c0-2.3,5.8-4.2,13.6-4.8l0.2-23.4c-2-2-3.3-4.8-3.2-7.9l0.5-16.3
	c0-0.9,0.4-1.8,1-2.5l1.8-2c0.1-0.1,0.1-0.2,0.1-0.4l-0.5-6.9C13.4,4.3,15,1.7,17.5,0.6z M20,50.8v17.9c-0.3,0.1-0.6,0.1-1,0.1
	c-0.7,0-1.4-0.1-2.1-0.3c-0.5-0.1-0.9-0.6-0.9-1.3l0.1-17.5C17,50.1,18.9,50.7,20,50.8z M25.2,51.4c0,1,0.6,1.8,1.5,2.2v9
	c0,2.8,2,5.2,4.8,5.7l1.5,0.3c0.6,0.1,1,0.6,1,1.2c0,0.6-0.4,1.1-0.9,1.1c-2.5,0.4-6.9,1-10.2,0.4c-0.2,0-0.5-0.3-0.5-0.6V45
	c0-0.7-0.6-1.3-1.3-1.3c-4.6,0-8.3-3.8-8.1-8.4L13.6,19c0-0.4,0.2-0.7,0.4-1l1.8-2c0.5-0.6,0.8-1.3,0.7-2.1L16,7
	c-0.1-1.8,0.9-3.5,2.5-4.2c2.3-1,5,0.2,5.8,2.6l3.5,10.4c0.1,0.3,0.2,0.5,0.4,0.6c1.8,1.5,2.8,3.6,2.8,5.9v25.2
	c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7V24.3h-2.4v23.3v0V51.4z"/>
    <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" d="M20,50.8v17.9c-0.3,0.1-0.6,0.1-1,0.1c-0.7,0-1.4-0.1-2.1-0.3
	c-0.5-0.1-0.9-0.6-0.9-1.3l0.1-17.5C17,50.1,18.9,50.7,20,50.8z M25.2,51.4c0,1,0.6,1.8,1.5,2.2v9c0,2.8,2,5.2,4.8,5.7l1.5,0.3
	c0.6,0.1,1,0.6,1,1.2c0,0.6-0.4,1.1-0.9,1.1c-2.5,0.4-6.9,1-10.2,0.4c-0.2,0-0.5-0.3-0.5-0.6V45c0-0.7-0.6-1.3-1.3-1.3
	c-4.6,0-8.3-3.8-8.1-8.4L13.6,19c0-0.4,0.2-0.7,0.4-1l1.8-2c0.5-0.6,0.8-1.3,0.7-2.1L16,7c-0.1-1.8,0.9-3.5,2.5-4.2
	c2.3-1,5,0.2,5.8,2.6l3.5,10.4c0.1,0.3,0.2,0.5,0.4,0.6c1.8,1.5,2.8,3.6,2.8,5.9v25.2c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7
	V24.3h-2.4v23.3v0V51.4z"/>
    <g>
      <defs>
        <rect id="SVGID_1_" x="49.3" y="32.4" width="150.7" height="36.4"/>
      </defs>
      <clipPath id="SVGID_00000065789202387102656210000014825571061046592188_">
        <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
      </clipPath>
      <g style="clip-path:url(#SVGID_00000065789202387102656210000014825571061046592188_);">
        <path d="M56.1,60.3c-1.2-0.6-2-1.4-2.6-2.5c-0.6-1.1-0.9-2.4-0.9-3.8l-0.1-7.1h-3.2v-5h3.3v-4.9l6.6-1.5l-0.1,6.4h6.9v5h-7l0.1,6
			c0,0.7,0.1,1.3,0.4,1.7c0.3,0.5,0.6,0.8,1,1c0.4,0.3,0.9,0.4,1.4,0.4c0.7,0,1.4-0.2,1.9-0.5c0.6-0.3,1.1-0.8,1.7-1.4l1.6,4.3
			c-0.8,0.9-1.8,1.6-3,2.1c-1.2,0.5-2.5,0.8-3.8,0.8C58.6,61.2,57.2,60.9,56.1,60.3z"/>
        <path d="M70.2,38.7c-0.7-0.7-1.1-1.6-1.1-2.7c0-0.7,0.2-1.3,0.5-1.9c0.3-0.6,0.8-1,1.4-1.3c0.6-0.3,1.3-0.5,2-0.5
			c1.2,0,2.1,0.4,2.8,1c0.7,0.7,1.1,1.6,1.1,2.6c0,0.7-0.2,1.4-0.5,1.9c-0.3,0.6-0.8,1-1.4,1.3c-0.6,0.3-1.3,0.5-2,0.5
			C71.8,39.7,70.9,39.4,70.2,38.7z M69.6,41.8h6.6v18.7h-6.6V41.8z"/>
        <path d="M95.7,42.1c0.9,0.5,1.5,1.2,2,2.1c0.5,0.9,0.7,2,0.7,3.2v13.1h-6.6v-11c0-0.9-0.2-1.5-0.7-2c-0.4-0.5-1-0.7-1.8-0.7
			c-0.6,0-1.1,0.2-1.6,0.4c-0.5,0.3-0.8,0.7-1.1,1.2c-0.3,0.5-0.4,1.1-0.4,1.8v10.3h-6.6V41.8h5.5l1.1,3.5h0.1
			c0.7-1.3,1.5-2.2,2.6-2.9c1.1-0.7,2.3-1.1,3.7-1.1C93.8,41.4,94.9,41.6,95.7,42.1z"/>
        <path d="M105.6,68.4c-1.2-0.3-2.2-0.6-3.2-1c-1-0.5-1.8-1-2.4-1.6l2.1-4.9c0.5,0.5,1.1,0.9,1.8,1.3c0.7,0.4,1.5,0.6,2.2,0.8
			c0.8,0.2,1.6,0.3,2.4,0.3c1.1,0,2-0.2,2.8-0.6c0.8-0.4,1.4-0.9,1.9-1.6c0.5-0.7,0.7-1.6,0.7-2.5v-1.6h-0.1
			c-0.6,1.2-1.4,2.2-2.4,2.8c-1,0.7-2.1,1-3.3,1c-1.4,0-2.5-0.3-3.4-0.9c-0.9-0.6-1.7-1.4-2.2-2.4c-0.6-1.1-0.8-2.3-0.8-3.7V41.8
			h6.6v10.8c0,0.6,0.1,1.2,0.3,1.6c0.2,0.5,0.5,0.8,0.9,1.1c0.4,0.3,0.8,0.4,1.3,0.4c0.6,0,1-0.2,1.5-0.4c0.5-0.3,0.8-0.6,1.1-1.1
			c0.3-0.5,0.4-1.1,0.4-1.7V41.8h6.6V59c0,2-0.5,3.7-1.4,5.2c-0.9,1.5-2.3,2.6-4,3.4c-1.7,0.8-3.7,1.2-5.9,1.2
			C107.9,68.8,106.7,68.7,105.6,68.4z"/>
        <path d="M123.8,41.8h5.2l1,3.6h0.1c0.5-1.3,1.3-2.3,2.5-3c1.2-0.7,2.4-1.1,3.8-1.1c1.4,0,2.6,0.4,3.5,1.1c0.9,0.7,1.6,1.7,2,3.1
			h0.1c0.6-1.4,1.5-2.4,2.7-3.1c1.2-0.7,2.4-1.1,3.8-1.1c1.2,0,2.2,0.3,3,0.8c0.9,0.5,1.5,1.3,1.9,2.3c0.5,1,0.7,2.2,0.7,3.5v12.6
			h-6.5l-0.1-11c0-0.9-0.2-1.5-0.7-1.9c-0.4-0.5-1-0.7-1.7-0.7c-0.6,0-1.1,0.2-1.5,0.4c-0.4,0.3-0.8,0.7-1,1.2
			c-0.2,0.5-0.3,1.1-0.3,1.8l-0.1,10.3h-6.6v-11c0-0.9-0.2-1.5-0.6-1.9c-0.4-0.5-1-0.7-1.8-0.7c-0.9,0-1.6,0.3-2.1,0.9
			c-0.5,0.6-0.8,1.4-0.8,2.4v10.3h-6.6L123.8,41.8L123.8,41.8z"/>
        <path d="M159.6,60.3c-1-0.5-1.8-1.1-2.3-1.9c-0.6-0.8-0.8-1.7-0.8-2.8c0-1.7,0.7-3,2.1-4.1c1.4-1.1,3.4-1.8,6-2l3.2-0.3
			c0.4-0.1,0.7-0.2,1-0.5c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.5-0.6-0.9-0.7c-0.4-0.2-0.8-0.3-1.3-0.3
			c-0.6,0-1.1,0.2-1.6,0.4c-0.5,0.3-0.8,0.6-1.1,1.1c-0.3,0.5-0.5,1-0.5,1.6l-6.4-0.9c0.1-1.3,0.6-2.5,1.3-3.6
			c0.8-1.1,1.9-1.9,3.2-2.5c1.4-0.6,3-0.9,4.8-0.9c1.9,0,3.5,0.3,4.9,1c1.4,0.7,2.5,1.6,3.2,2.7c0.8,1.2,1.1,2.5,1.1,4.1v6.5
			c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.7-0.2c0.3-0.1,0.5-0.3,0.7-0.5l1.2,3.4c-0.6,0.5-1.4,0.9-2.3,1.2
			c-0.9,0.3-1.9,0.5-2.8,0.5c-0.8,0-1.6-0.2-2.3-0.4c-0.7-0.3-1.3-0.7-1.7-1.1c-0.5-0.5-0.8-1-0.9-1.6l-0.1-0.5h-0.1
			c-0.4,0.7-0.9,1.4-1.6,1.9c-0.6,0.6-1.4,1-2.2,1.3c-0.9,0.3-1.7,0.5-2.7,0.5C161.6,61,160.6,60.8,159.6,60.3z M167.4,56.1
			c0.6-0.3,1-0.7,1.2-1.2c0.3-0.6,0.5-1.1,0.5-1.8v-0.6l-2.7,0.3c-1.2,0.1-2,0.4-2.5,0.7c-0.5,0.4-0.8,0.8-0.8,1.4
			c0,0.5,0.2,0.9,0.7,1.2c0.5,0.3,1.1,0.5,1.8,0.5C166.3,56.6,166.9,56.4,167.4,56.1z"/>
        <path d="M197.3,42.1c0.9,0.5,1.5,1.2,2,2.1c0.5,0.9,0.7,2,0.7,3.2v13.1h-6.6v-11c0-0.9-0.2-1.5-0.7-2c-0.4-0.5-1-0.7-1.8-0.7
			c-0.6,0-1.1,0.2-1.6,0.4s-0.8,0.7-1.1,1.2c-0.3,0.5-0.4,1.1-0.4,1.8v10.3h-6.6V41.8h5.5l1.1,3.5h0.1c0.7-1.3,1.5-2.2,2.6-2.9
			c1.1-0.7,2.3-1.1,3.7-1.1C195.4,41.4,196.4,41.6,197.3,42.1z"/>
      </g>
    </g>
  </svg>
</template>
