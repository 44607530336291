<style lang="scss">
  @import "@/assets/scss/booger-humble";
</style>

<script setup>

</script>

<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 33.7 35.5"
    class="booger-humble"
  >
    <path style="fill:#1A1915;" d="M16.9,23.5c-9.4,0-16.7-5.2-16.7-11.8h6c0,2.7,4.4,5.8,10.7,5.8c6.3,0,10.7-3.1,10.7-5.8h6 C33.5,18.3,26.2,23.5,16.9,23.5z"/>
    <path style="fill:#1A1915;" d="M6.2-0.2h-6.3v35.8h6.3V20.6v-5.8V-0.2z"/>
    <path style="fill:#1A1915;" d="M27.5-0.2v14.9v5.8v15h6.3V-0.2H27.5z"/>
    <path style="fill:#FEBE20;" d="M16.9,13c3.5,0,6.3-2.8,6.3-6.2c0-3.4-2.8-6.2-6.3-6.2s-6.3,2.8-6.3,6.2C10.6,10.2,13.4,13,16.9,13z"/>
  </svg>
</template>
